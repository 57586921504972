import React, { Component, Suspense } from 'react';

import { BrowserRouter , Routes, Route, Navigate } from "react-router-dom";

import './scss/style.scss';

const AcercaDe = React.lazy(() => import('./pages/website/es/aboutus/AboutUs'));
const Inicio = React.lazy(() => import('./pages/website/es/home/Home'));
const Privacidad = React.lazy(() => import('./pages/website/es/privacy/Privacy'));
const Terminos = React.lazy(() => import('./pages/website/es/terms/Terms'));
const ComoUsar = React.lazy(() => import('./pages/website/es/howuse/HowUse'));
const ConfirmaCuenta = React.lazy(() => import('./pages/website/es/confirmAccount/ConfirmAccount'));

const AboutUs = React.lazy(() => import('./pages/website/en/aboutus/AboutUs'));
const Home = React.lazy(() => import('./pages/website/en/home/Home'));
const Privacy = React.lazy(() => import('./pages/website/en/privacy/Privacy'));
const Terms = React.lazy(() => import('./pages/website/en/terms/Terms'));
const HowUse = React.lazy(() => import('./pages/website/en/howuse/HowUse'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<div>Cargando...</div>}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    navigator.language
                      ? (navigator.language.startsWith('es') ? '/es/' : '/en/')
                      : '/en/' // Valor predeterminado
                  }
                  replace
                />
              }
            />

            {/* Rutas en español */}
            <Route path="/es/" element={<Inicio />} />
            <Route path="/es/aboutus" element={<AcercaDe />} />
            <Route path="/es/privacy" element={<Privacidad />} />
            <Route path="/es/terms" element={<Terminos />} />
            <Route path="/es/howuse" element={<ComoUsar />} />
            <Route path="/es/confirmAccount/:token" element={<ConfirmaCuenta />} />
            {/* Rutas en inglés */}
            <Route path="/en/" element={<Home />} />
            <Route path="/en/aboutus" element={<AboutUs />} />
            <Route path="/en/privacy" element={<Privacy />} />
            <Route path="/en/terms" element={<Terms />} />
            <Route path="/en/howuse" element={<HowUse />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
